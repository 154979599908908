<template>
    <el-descriptions
        class="distance-detail"
        :title="title"
        :column="3"
        size="mini"
        :key="key"
        border
    >
        <el-descriptions-item label="均值">{{ mean }}</el-descriptions-item>
        <el-descriptions-item label="中位数">{{ median }}</el-descriptions-item>
        <el-descriptions-item label="众数">{{ mode }}</el-descriptions-item>
        <el-descriptions-item label="标准差">{{ std }}</el-descriptions-item>
        <el-descriptions-item label="最大值">{{ max }}</el-descriptions-item>
        <el-descriptions-item label="最小值">{{ min }}</el-descriptions-item>
        <el-descriptions-item label="正常范围">{{
            normal
        }}</el-descriptions-item>
    </el-descriptions>
</template>
<script>
import { mean, median, mode, std, max, min } from "mathjs";
export default {
    name: "DistanceDetail",
    props: {
        title: {
            type: String,
            default: () => ""
        },
        samples: {
            type: Array,
            default: () => []
        },
        maxNumber: {
            type: Number,
            default: () => 0xffffffff
        },
        minNumber: {
            type: Number,
            default: () => 0x00000000
        }
    },
    watch: {
        samples: {
            handler() {
                this.reback();
            }
        }
    },
    computed: {
        mean() {
            return mean(this.samples);
        },
        median() {
            return median(this.samples);
        },
        mode() {
            return mode(this.samples);
        },
        std() {
            return std(this.samples);
        },
        max() {
            return max(this.samples);
        },
        min() {
            return min(this.samples);
        },
        normal() {
            let std3 = 3 * this.std;
            let floor = Math.floor(this.mean - std3);
            let ceil = Math.ceil(this.mean + std3);
            return [
                floor < this.minNumber ? this.minNumber : floor,
                ceil > this.maxNumber ? this.maxNumber : ceil
            ];
        }
    },
    data() {
        return {
            key: 0
        };
    },
    methods: {
        reback() {
            this.key++;
            let { mean, median, mode, std, max, min, normal } = this;
            this.$emit("callBack", {
                mean,
                median,
                mode,
                std,
                max,
                min,
                normal
            });
        }
    },
    mounted() {
        this.reback();
    }
};
</script>
<style lang="scss" scoped></style>
